import React from 'react';
import {ReactComponent as Logo} from './images/entersound2.svg'
import './App.scss';
import ReactPlayer from 'react-player';

export default class App extends React.Component {
    render()
    {
        const spotifyUrl = 'https://embed.spotify.com/?uri=spotify:playlist:7jksxMtSbAmOrO1sm8gupu&view=list&theme=white';
        const soundCloudUrl = 'https://soundcloud.com/ntrsound';
        return (
            <div className="App">
                <div className="screenView--landing">
                    <Logo className="landingLogo" data-testid="mainLogo"/>
                    <div className="email">
                        <a href="mailto:thomas.lam@enter-sound.com">thomas.lam@enter-sound.com</a>
                    </div>
                </div>
                <div className="screenView--players">
                    <iframe
                        className="SpotifyPlayer"
                        frameBorder="0"
                        height="600"
                        src={spotifyUrl}
                        title="Spotify"
                        width="48%"
                        data-testid="spotifyPlayer"
                    />
                    <div className="verticalDivider"/>
                    <ReactPlayer url={soundCloudUrl} width='48%' height='600px' data-testid="soundcloudPlayer"/>
                </div>
            </div>
        );
    }
}
